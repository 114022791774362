// import { useEffect, useState } from "react";
// import { HandleApi } from "../../api/api";
import { ParticipationWrap } from '../styled';
import Table from './Table';

const ParticipationTable = ({data}) => {
	const codes = {};
	if (data?.referral?.codes) {
		for (let code of data?.referral.codes) {
			codes[code.referral_code] = code;
		}
	}

	return (
		<ParticipationWrap>
			{data?.purchases && (
				<>
					<h1>My Purchases</h1>
					<Table records={data.purchases} />
				</>
			)}
			{data?.referral?.purchases && Object.entries(data.referral.purchases).length > 0 ? (
				<>
					<h1>My Referral purchases</h1>
					{Object.entries(data.referral.purchases).map(
						([key, value]) => (
							<div>
								<h3>
									{key}{' '}
									<small>
										(Total reward:{' '}
										{codes[key] ? codes[key].reward : 0})
									</small>
								</h3>
								<Table records={value} />
							</div>
						)
					)}
				</>
			) : (
				<><h1>My Referral purchases</h1><Table records={[]}/></>
			)}
		</ParticipationWrap>
	)
}

export default ParticipationTable;
