import { Tabs, Tab, Typography } from '@material-ui/core';
import { DetailsModal, TabWrap } from './styled';
import { useState } from 'react';
import { ReactComponent as CloseImg } from '../assets/close.svg';
import Summary from './Summary';
import Tokenomics from './Tokenomics/Tokenomics';

const CustomTab = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<TabWrap
			role='tabpanel'
			hidden={value !== index}
			id={`details-tabpanel-${index}`}
			aria-labelledby={`details-tab-${index}`}
			{...other}
		>
			{value === index && <Typography>{children}</Typography>}
		</TabWrap>
	);
};

const Modal = ({ open, setOpen }) => {
	const [tab, setTab] = useState(0);
	return (
		<DetailsModal
			open={open}
			fullWidth
			onClose={() => setOpen(false)}
			scroll='body'
			maxWidth='lg'
		>
			<CloseImg onClick={() => setOpen(false)} />
			<h1>Event details</h1>
			<Tabs value={tab} onChange={setTab}>
				<Tab
					label='Summary'
					id='details-tab-1'
					onClick={() => setTab(0)}
				/>
				<Tab
					label='Tokenomics'
					id='details-tab-2'
					onClick={() => setTab(1)}
				/>
			</Tabs>
			<CustomTab value={tab} index={0}>
				<Summary />
			</CustomTab>
			<CustomTab value={tab} index={1}>
				<Tokenomics />
			</CustomTab>
		</DetailsModal>
	);
};

export default Modal;
