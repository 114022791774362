import { useEffect, useState } from 'react';

import Button from '../Button';
import { Flex } from '../Common';
import { Close as IconClose, Menu as IconMenu } from '@material-ui/icons';

// import Logo from "../../Icon.png";
import { ReactComponent as Logo } from '../../assets/logo.svg';

import { Header, MobileMenu } from './styled';
import ConnectOrChangeWallet from '../ConnectOrChangeWallet';
import { styled } from 'styled-components';
const StyledLogo = styled(Logo)`
	color: #fff;
`;

const MainHeader = () => {
	const [ww, setWw] = useState(window.innerWidth);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const setWindowWidth = () => {
			setWw(window.innerWidth);
		};
		window.addEventListener('resize', setWindowWidth);
    setWindowWidth();
		return () => window.removeEventListener('resize', setWindowWidth);
	}, []);

	return (
		<Header open={open}>
			{ww > 1024 ? (
				<Flex align='center' justify='space-between'>
					<StyledLogo />
					<Flex align='center' gap={20}>
						<a
							href='https://app.cerra.io'
							target='_blank'
							rel='noreferrer'
						>
							<Button variant='outlined'>Launch App</Button>
						</a>
						<ConnectOrChangeWallet />
					</Flex>
				</Flex>
			) : (
				<Flex align='center' justify='space-between'>
					<StyledLogo />
					{!open ? (
						<IconMenu onClick={() => setOpen(true)} />
					) : (
						<IconClose onClick={() => setOpen(false)} />
					)}

					<MobileMenu>
						<a
							href='https://cerra.io'
							target='_blank'
							rel='noreferrer'
						>
							Read more about Cerra
						</a>
						<a
							href='https://app.cerra.io'
							target='_blank'
							rel='noreferrer'
						>
							Go to App
						</a>
						<ConnectOrChangeWallet />
					</MobileMenu>
				</Flex>
			)}
			{/* <Dialog onClose={() => setShowWalletSelectModal(false)} open={showWalletSelectModal}>
                <ChooseWalletWrap>
                    <h3>Choose Wallet</h3>
                    <ConnectOrChangeWallet change={!!store.wallet} />
                </ChooseWalletWrap>
            </Dialog> */}
		</Header>
	);
};

export default MainHeader;
