import {
	Hero,
	Wrap,
	GoalProgress,
	ChartInfoWrap,
	TimerWrap,
	InfoWrap,
	ChartAndInfoWrap,
} from './styled';
import { Grid } from '../components/Common';
import {
	useConnectWallet,
	WalletModal,
} from '@newm.io/cardano-dapp-wallet-connector';
import Countdown from '../components/Countdown';
import Button from '../components/Button';
import BuyForm from './BuyForm';
import ReferralSection from './Referral';
import { useCallback, useState } from 'react';
import DetailsModal from './DetailsModal';
import ParticipationTable from './ParticipationTable';
// import { ReactComponent as ChartImg } from "../assets/fullChart.svg";
import SaleChart from '../components/SaleChart';
import Spinner from '../components/Spinner';
import Footer from '../components/Footer';

const PublicSale = ({ info, walletInfo, wallet }) => {
	const { isConnected } = useConnectWallet();
	const [walletModalOpen, setWalletModalOpen] = useState(false);
	const [detailsModalOpen, setDetailsModalOpen] = useState(false);

	const ADARaised = useCallback(() => {
		if (!info?.ADA_raised) return <Spinner />;
		const v = parseFloat(info?.ADA_raised);
		if (v > 1000000) {
			return `${parseFloat(v / 1000000).toLocaleString(undefined, {
				maximumFractionDigits: 3,
			})}M`;
		} else {
			return v.toLocaleString(undefined, { maximumFractionDigits: 0 });
		}
	}, [info]);

	return (
		<Wrap>
			<div>
				<Hero>
					<Grid $mobileGap={20} $align='center'>
						<div>
							<h1>
								Prorata Cerra Token
								<br />
								Sale Event
							</h1>
							<p style={{ color: 'var(--text)' }}>
								Own a Share of the Decentralized Profit-Sharing
								Finance Hub on Cardano
							</p>
							<Button onClick={() => setDetailsModalOpen(true)}>
								Event details
							</Button>
						</div>
						<ChartAndInfoWrap>
							<SaleChart percent={info.percent_raised} />
							<ChartInfoWrap>
								<h4>
									Contributed so far:
									<br />
									<span>₳ {ADARaised()}</span>
								</h4>
								<GoalProgress
									level={parseInt(
										parseFloat(info?.percent_raised || 0) /
											100
									)}
								>
									<div>
										<div />
										<span>Goal</span>
									</div>
									<div>
										<div />
										<span>X2</span>
									</div>
									<div>
										<div />
										<span>X3</span>
									</div>
									<div>
										<div />
										<span>X4</span>
									</div>
								</GoalProgress>
								<TimerWrap>
									<Countdown
										startDate={window.SALE_START_UTC}
										endDate={window.SALE_END_UTC}
									/>
								</TimerWrap>
							</ChartInfoWrap>
						</ChartAndInfoWrap>
					</Grid>
				</Hero>
				<InfoWrap
					$mobileGap={20}
					$align='stretch'
					$coXll={3}
					$col={3}
					$disableAt={1024}
				>
					<div>
						<p>
							{parseFloat(
								info.tokens_allocated || 0
							).toLocaleString()}
						</p>
						<span>Total CERRA tokens allocated for the sale</span>
					</div>
					<div>
						<p>
							₳{' '}
							{parseFloat(
								info.max_ADA_target || 0
							).toLocaleString()}
						</p>
						<span>Total Raise Goal</span>
					</div>
					<div>
						<p>{info.contribution_transaction_count || 0}</p>
						<span>Contributions</span>
					</div>
					<div>
						<p>
							₳{' '}
							{parseFloat(
								info.token_per_one_ADA || 0
							).toLocaleString(undefined, {
								maximumFractionDigits: 4,
							})}
						</p>
						<span>Estimated Token Price</span>
					</div>
					<div>
						<p>
							₳{' '}
							{parseFloat(
								info.ADA_to_be_refunded || 0
							).toLocaleString()}
						</p>
						<span>ADA to be refunded</span>
					</div>{' '}
					<div>
						<p>₳ 0.8</p>
						<span>CERRA Price if Goal is reached</span>
					</div>
				</InfoWrap>
				<BuyForm
					isConnected={isConnected}
					setWalletModalOpen={setWalletModalOpen}
					stats={info}
					walletInfo={walletInfo}
					wallet={wallet}
				/>
				<ReferralSection
					setWalletModalOpen={setWalletModalOpen}
					data={walletInfo}
					info={info}
				/>
				{isConnected && <ParticipationTable data={walletInfo} />}
			</div>

			<div className='wallet-modal'>
				<WalletModal
					isOpen={walletModalOpen}
					onClose={() => setWalletModalOpen(false)}
					isInverted={true}
				/>
			</div>
			<DetailsModal
				open={detailsModalOpen}
				setOpen={() => setDetailsModalOpen(false)}
			/>
			<Footer />
		</Wrap>
	);
};

export default PublicSale;
