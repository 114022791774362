import { Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { AdaImage, CerraImage } from "../utils";

const Th = ({ children, ...rest }) => (
  <TableCell {...rest} style={{ color: "white", fontWeight: "bold" }}>
    {children}
  </TableCell>
);

const Td = ({ children, ...rest }) => (
  <TableCell {...rest} style={{ color: "white" }}>
    {children}
  </TableCell>
);

const ParticipationTable = ({ records }) => {
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="locked tx table" sx={{ color: "#fff" }} size="small">
        <TableHead>
          <TableRow>
            <Th>Purchased at</Th>
            <Th>Tx Hash</Th>
            <Th>Quantity (ADA)</Th>
            <Th>Eligible (ADA)</Th>
            <Th>Refund (ADA)</Th>
            <Th>Quantity (CERRA)</Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {!records?.length ? (
            <TableRow>
              <Td>No data found</Td>
            </TableRow>
          ) : (
            records.map((row) => (
              <TableRow key={row.transaction_hash}>
                <Td>{row.logged_at}</Td>
                <Td>
                  <a
                    style={{
                      display: "block",
                      width: "200px",
                      overflow: "hidden",
                      color: "var(--special)",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    href={`${
                      window.NETWORK === "Mainnet"
                        ? "https://cardanoscan.io/transaction/"
                        : "https://testnet.cardanoscan.io/transaction/"
                    }${row.transaction_hash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {row.transaction_hash}
                  </a>
                </Td>
                <Td>
                  <AdaImage />
                  {row.ada_amount}
                </Td>
                <Td>
                  <AdaImage />
                  {row.ada_eligible_amount}
                </Td>
                <Td>
                  <AdaImage />
                  {row.ada_refund_amount}
                </Td>
                <Td>
                  <CerraImage />
                  {row.cerra_tokens}
                </Td>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ParticipationTable;
