import React, { useEffect, useRef, useState } from 'react';
import StakingImg from '../../assets/staking.png';

import styled, { css } from 'styled-components';

import {
	TokenomicsCandle,
	TokenomicsHeader,
	TokenomicsRow,
	TokenomicsSection,
	TokenomicsWrap,
} from './styled';
import { GradientBlock, SummaryWrapper } from '../styled';

const DivBase = css`
	padding: 50px 40px;
	@media (max-width: 767px) {
		padding: 20px;
	}
`;
const Grid = styled.div`
	@media (min-width: ${({ disableAt }) => disableAt ? `${disableAt}px` : '1024px'}) {
		display: grid;
		grid-template-columns: ${({ col, colExact }) =>
			colExact ||
			(col
				? `repeat(${col}, minmax(0, 1fr))`
				: 'repeat(2, minmax(0, 1fr))')};
		grid-gap: ${({ gap }) => (gap ? `${gap}px` : '20px')};
		align-items: ${({ align }) => align || 'start'};
	}
	${({ colXs, colXsExact }) =>
		colXs || colXsExact
			? `
        @media (max-width: 600px) {
            grid-template-columns: ${
				colXsExact ||
				(colXs
					? `repeat(${colXs}, minmax(0, 1fr))`
					: 'repeat(2, minmax(0, 1fr))')
			}};
        }
    `
			: ''}
	${({ colXl, colXlExact }) =>
		colXl || colXlExact
			? `
        @media (min-width: 1600px) {
            grid-template-columns: ${
				colXlExact ||
				(colXl
					? `repeat(${colXl}, minmax(0, 1fr))`
					: 'repeat(2, minmax(0, 1fr))')
			}};
        }
    `
			: ''}
    @media (max-width: ${({ disableAt }) => disableAt ? `${disableAt}px` : '1024px'}) {
		${({ mobileGap }) =>
			mobileGap &&
			`
                & > * + * {
                    margin-top: ${mobileGap}px;
                }
        `};
		${({ gapMobile }) =>
			gapMobile &&
			`
            & > * {
                margin-top: ${gapMobile}px;
            }
        `};
	}
`;

const tokenomicsChartData = [
	{
		label: 'Yield rewards',
		percent: 30,
		height: 200,
	},
	{
		label: 'Public sale',
		percent: 20,
		height: 170,
	},

	{
		label: 'Vault',
		percent: 10,
		height: 130,
	},
	{
		label: 'Team',
		percent: 10,
		height: 130,
	},
	{
		label: 'Exchange liquidity',
		percent: 10,
		height: 130,
	},
	{
		label: 'Development',
		percent: 10,
		height: 130,
	},
	{
		label: 'ISPO',
		percent: 5,
		height: 80,
	},
	{
		label: 'Pre-sale',
		percent: 5,
		height: 80,
	},
];

const TokenUtilityWrap = styled.div`
	margin: 100px 0;
	h2 {
		text-align: center;
		& + p {
			margin: 10px auto 50px;
			max-width: 600px;
			color: var(--subtext);
		}
	}
	img {
		width: 70%;
		margin: 0 auto;
		margin-top: 10px;
	}
	& > div > div {
		border-radius: 10px;
		${DivBase};
		&:nth-child(1) {
			grid-column: 1 / 3;
			grid-row: 1 / 3;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		&:nth-child(3) {
			grid-column: 5 / 7;
			grid-row: 1 / 2;
		}
		&:nth-child(2) {
			grid-column: 3 / 5;
			grid-row: 1 / 2;
		}
		&:nth-child(4) {
			grid-column: 3 / 5;
			grid-row: 2 / 3;
		}
		&:nth-child(5) {
			grid-column: 5 / 7;
			grid-row: 2 / 3;
		}
		h4 {
			margin-bottom: 10px;
		}
		p {
			color: var(--subtext);
			font-size: 16px;
		}
	}
	@media (max-width: 1024px) {
		margin: 80px 0 40px;
		img {
			max-width: 150px;
		}
	}
`;

const Tokenomics = () => {
	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef();

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => {
			setIsVisible(entry.isIntersecting);
		});

		if (ref.current) {
			observer.observe(ref.current);
		}

		if (isVisible) {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [isVisible]);

	return (
		<SummaryWrapper>
			<TokenomicsSection>
				{/* {window.innerWidth > 1024 && <video autoPlay loop muted playsInline src={VideoMetaball} />} */}
				<TokenomicsWrap id='tokenomics'>
					<div>
						<p className='title'>Tokenomics</p>
						<span>Total supply 50M CERRA tokens</span>
						<br />
						<br />
						<GradientBlock>
							<TokenomicsHeader
								colExact='repeat(4, minmax(0, 1fr)) minmax(0, 2fr)'
								align='center'
								gap={1}
							>
								<div>Supply</div>
								<div>% of total supply</div>
								<div>Lockup period</div>
								<div>Vesting period</div>
								<div>Description</div>
							</TokenomicsHeader>
							{/* <TokenomicsRow col={4} align="center" gap={1}>
                <div>Advisors</div>
                <div>5%</div>
                <div>12 months</div>
                <div>12 months post lockup</div>
            </TokenomicsRow> */}
							<TokenomicsRow
								colExact='repeat(4, minmax(0, 1fr)) minmax(0, 2fr)'
								align='center'
								gap={1}
							>
								<div>Team</div>
								<div>10%</div>
								<div>12 months</div>
								<div>24 months</div>
								<div>
									Founding Members Allocation, Linear Vesting
								</div>
							</TokenomicsRow>
							<TokenomicsRow
								colExact='repeat(4, minmax(0, 1fr)) minmax(0, 2fr)'
								align='center'
								gap={1}
							>
								<div>Development</div>
								<div>10%</div>
								<div>12 months</div>
								<div>12 months</div>
								<div>
									Allocation for
									Development/Servers/Audits/Oracles, Tranche
									Based
								</div>
							</TokenomicsRow>
							<TokenomicsRow
								colExact='repeat(4, minmax(0, 1fr)) minmax(0, 2fr)'
								align='center'
								gap={1}
							>
								<div>Exchange Liquidity</div>
								<div>10%</div>
								<div>6 months</div>
								<div>6 months</div>
								<div>
									Allocation to be supplied to DEX Liquidity
									Pools for CERRA/ADA Pairs
								</div>
							</TokenomicsRow>
							<TokenomicsRow
								colExact='repeat(4, minmax(0, 1fr)) minmax(0, 2fr)'
								align='center'
								gap={1}
							>
								<div>Yield Rewards</div>
								<div>30%</div>
								<div>3 months</div>
								<div>36 months</div>
								<div>
									Rewards distributed to liquidity providers
									on Cerra.io / DEXes
								</div>
							</TokenomicsRow>
							<TokenomicsRow
								colExact='repeat(4, minmax(0, 1fr)) minmax(0, 2fr)'
								align='center'
								gap={1}
							>
								<div>Vault</div>
								<div>10%</div>
								<div>6 months</div>
								<div>24 months</div>
								<div>
									Partnerships / Marketing Efforts / Events
								</div>
							</TokenomicsRow>
							<TokenomicsRow
								colExact='repeat(4, minmax(0, 1fr)) minmax(0, 2fr)'
								align='center'
								gap={1}
							>
								<div>Private sale</div>
								<div>5%</div>
								<div>6 months</div>
								<div>---</div>
								<div>
									Private Sale for early token adopters,
									locked via Smart Contract On-chain
								</div>
							</TokenomicsRow>
							<TokenomicsRow
								colExact='repeat(4, minmax(0, 1fr)) minmax(0, 2fr)'
								align='center'
								gap={1}
							>
								<div>Public sale</div>
								<div>20%</div>
								<div>---</div>
								<div>---</div>
								<div>Public Sale / LBE</div>
							</TokenomicsRow>
							<TokenomicsRow
								colExact='repeat(4, minmax(0, 1fr)) minmax(0, 2fr)'
								align='center'
								gap={1}
							>
								<div>ISPO</div>
								<div>5%</div>
								<div>6 months</div>
								<div>3 months</div>
								<div>Allocation for ISPO rewards</div>
							</TokenomicsRow>
						</GradientBlock>
					</div>
				</TokenomicsWrap>
				<div>
					<Grid col={8} gap={10} disableAt={1} align='end' ref={ref}>
						{tokenomicsChartData.map((i) => (
							<TokenomicsCandle key={i.label}>
								<span>{i.percent}%</span>
								<p>{i.label}</p>
								<div
									style={{
										height: `${isVisible ? i.height : 0}px`,
									}}
								/>
							</TokenomicsCandle>
						))}
					</Grid>
				</div>
			</TokenomicsSection>
			<br />
			<p className='title'>Token utility</p>
			<br />
			<span>
				Given that CERRA utility token holders are actual owners of the
				platform, they are incentivized to actively utilize and advocate
				for the platform. This active engagement directly enhances the
				value of their tokens. Entities holding a substantial portion of
				the total token supply are motivated to invest in and enhance
				the platform, thereby increasing returns not only for themselves
				but also for the wider community.
			</span>
			<TokenUtilityWrap id='utility'>
				<Grid col={6} align='stretch' mobileGap={20}>
					<GradientBlock>
						<div>
							<h4>Staking</h4>
							<span>
								Stake your $CERRA tokens, receive a proportional
								amount of <b>100% of platform profits.</b>
							</span>
						</div>
						<img src={StakingImg} alt='staking' />
					</GradientBlock>
					<GradientBlock>
						<h4>Holding</h4>
						<span>
							Holding $CERRA tokens will eventually lead to using
							the platform for free and making passive income.
						</span>
					</GradientBlock>
					<GradientBlock>
						<h4>DAO voting</h4>
						<span>
							Allocate your $CERRA tokens and contribute to
							Cerra.io development and operational direction.
						</span>
					</GradientBlock>
					<GradientBlock>
						<h4>Farming</h4>
						<span>
							Provide liquidity to AMM Pools / Lending Pools /
							Derivatives and receive $CERRA incentives.
						</span>
					</GradientBlock>
					<GradientBlock>
						<h4>Governance</h4>
						<span>
							Propose or vote on certain platform features,
							upgrades, resource allocation and more.
						</span>
					</GradientBlock>
				</Grid>
			</TokenUtilityWrap>
		</SummaryWrapper>
	);
};

export default Tokenomics;
