import React, { useEffect, useState } from 'react'
import moment from 'moment'

const Countdown = ({ startDate, endDate }) => {
    const [timeLeft, setTimeLeft] = useState({})
    const [label, setLabel] = useState('')

    useEffect(() => {
        const now = moment().utc()
        let difference
        if (now.isBefore(moment.utc(startDate))) {
            difference = moment(startDate).diff(now)
            setLabel('Starts in')
        } else if (now.isBefore(moment.utc(endDate))) {
            difference = moment(endDate).diff(now)
            setLabel('Ending in')
        } else {
            difference = 0
            setLabel('Ended')
        }

        function setTime(diff) {
            const duration = moment.duration(diff)
            setTimeLeft({
                days: duration.days() < 10 ? `0${duration.days()}` : duration.days(),
                hours: duration.hours() < 10 ? `0${duration.hours()}` : duration.hours(),
                minutes: duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes(),
                seconds: duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds(),
            })
        }

        setTime(difference)

        const interval = setInterval(() => {
            const now = moment().utc()
            let difference
            if (now.isBefore(moment.utc(startDate))) {
                difference = moment(startDate).diff(now)
                setLabel('Starts in')
            } else if (now.isBefore(moment.utc(endDate))) {
                difference = moment(endDate).diff(now)
                setLabel('Ending in')
            } else {
                difference = 0
                setLabel('Ended')
            }
            setTime(difference)
        }, 1000)

        return () => clearInterval(interval)
    }, [startDate, endDate])

    return (
        <div>
            <p>{`${timeLeft.days || 0} : ${timeLeft.hours || 0} : ${timeLeft.minutes || 0} : ${timeLeft.seconds || 0}`}</p>
            <span>{label}</span>
        </div>
    )
}

export default Countdown