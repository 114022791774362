import { MenuItem } from '@material-ui/core';
import styled, { css } from 'styled-components';

const Grid = styled.div`
	@media (min-width: ${({ disableAt }) =>
			disableAt ? `${disableAt}px` : '1024px'}) {
		display: grid;
		grid-template-columns: ${({ col, colExact }) =>
			colExact ||
			(col
				? `repeat(${col}, minmax(0, 1fr))`
				: 'repeat(2, minmax(0, 1fr))')};
		grid-gap: ${({ gap }) => (gap ? `${gap}px` : '20px')};
		align-items: ${({ align }) => align || 'start'};
	}
	${({ colXs, colXsExact }) =>
		colXs || colXsExact
			? `
        @media (max-width: 600px) {
            grid-template-columns: ${
				colXsExact ||
				(colXs
					? `repeat(${colXs}, minmax(0, 1fr))`
					: 'repeat(2, minmax(0, 1fr))')
			}};
        }
    `
			: ''}
	${({ colXl, colXlExact }) =>
		colXl || colXlExact
			? `
        @media (min-width: 1600px) {
            grid-template-columns: ${
				colXlExact ||
				(colXl
					? `repeat(${colXl}, minmax(0, 1fr))`
					: 'repeat(2, minmax(0, 1fr))')
			}};
        }
    `
			: ''}
    @media (max-width: ${({ disableAt }) =>
		disableAt ? `${disableAt}px` : '1024px'}) {
		${({ mobileGap }) =>
			mobileGap &&
			`
                & > * + * {
                    margin-top: ${mobileGap}px;
                }
        `};
		${({ gapMobile }) =>
			gapMobile &&
			`
            & > * {
                margin-top: ${gapMobile}px;
            }
        `};
	}
`;

const DivBase = css`
	padding: 50px 40px;
	@media (max-width: 767px) {
		padding: 20px;
	}
`;

export const Container = styled.div`
	max-width: ${({ width }) => width || '1200px'};
	padding: 0 20px;
	margin: 0 auto;
`;
export const Hero = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	align-items: center;
	h1,
	p,
	a {
		text-align: center;
		margin: 20px auto;
	}
	h1 {
		max-width: 900px;
	}
	p {
		max-width: 350px;
		color: var(--special);
		font-weight: 500;
		font-size: 16px;
	}

	img {
		margin: 0 auto;
		display: block;
		object-fit: cover;
		& + div {
			background: linear-gradient(
				270deg,
				rgba(95, 182, 70, 0.07) 12.06%,
				#5fb646 21.32%,
				#5fb646 67.09%,
				rgba(95, 182, 70, 0.07) 86.12%
			);
			height: 8px;
			width: 100vw;
			position: relative;
		}
	}
	@media (min-width: 1200px) {
		img {
			max-width: 1200px;
			max-height: calc(100vh - 330px);
			// width: 100%;
		}
	}
	@media (min-width: 1024px) {
		height: calc(100vh - 109px);
	}
	@media (max-width: 1200px) {
		img {
			width: 80%;
		}
	}
	@media (max-width: 1024px) {
		img + div {
			height: 5px;
		}
	}
`;

export const FormWrap = styled.div`
	margin: 100px auto;
	p {
		color: var(--subtext);
		span {
			color: var(--text);
		}
	}
	form {
		${DivBase};
		& > p {
			font-weight: 600;
			margin: 20px 0;
		}
	}
	h1 {
		margin-bottom: 20px;
	}
	button {
		display: block;
		margin: 20px 0 0 auto;
	}
	label {
		width: 50%;
		margin-right: 0;
	}
	@media (max-width: 1024px) {
		margin: 100px 20px 40px;
	}
`;

export const SuccessWrap = styled.p`
	color: var(--green);
`;
export const ErrorWrap = styled.p`
	color: var(--red);
`;

export const LandingWrap = styled.div`
	z-index: 3;
	@media (max-width: 1024px) {
		margin-top: 92px;
	}
`;

export const CopyTradingTitle = styled.p`
	margin: 10px 0;
	color: var(--text);
`;

export const FeatureWrap = styled.div`
	& > div > div {
		border-radius: 10px;
		background: var(--lbg);
		${DivBase};
		h4 {
			margin-bottom: 10px;
		}
		p:not(${CopyTradingTitle}) {
			color: var(--subtext);
		}
	}
`;
export const MoreFeatureWrap = styled.div`
	& > div > div p {
		margin: 10px 0 30px;
	}
	& > div + div {
		margin-top: 40px;
	}
	p {
		color: var(--subtext);
	}
	@media (max-width: 1024px) {
		& > div:first-child {
			display: flex;
			flex-direction: column-reverse;
		}
	}
`;
export const WelcomeWrap = styled.div`
	border-radius: 10px;
	margin: 100px 0;
	p {
		color: var(--subtext);
		margin: 10px 0 30px;
	}
	& > div > div {
		padding: 20px;
	}
	& > div > img {
		width: 80%;
		border-radius: 10px;
		object-fit: cover;
	}
	& > div > div:first-of-type a {
		color: var(--special);
	}

	li {
		text-align: left;
		color: var(--subtext);
	}
	h4 {
		margin-bottom: 10px;
	}
	ul {
		margin-bottom: 20px;
		margin-left: 20px;
	}
	@media (max-width: 1024px) {
		margin: 40px 0;
		& > div {
			display: flex;
			flex-direction: column-reverse;
		}
		a {
			padding: 10px;
		}
	}
`;
export const TokenomicsSection = styled.section`
	position: relative;
	video {
		position: absolute;
		top: 0;
		left: 30%;
		z-index: 1;
	}
	& > div {
		position: relative;
		z-index: 2;
	}
	@media (max-width: 1300px) {
		video {
			left: 10%;
		}
	}
`;
export const TokenomicsWrap = styled.div`
	h2 + p {
		margin: 10px 0 50px;
	}
	& > div > div {
		${DivBase};
		border-radius: 10px;
		overflow: hidden;
		padding: 20px;
		font-weight: 500;
		& > div + div {
			margin-top: 1px;
		}
	}
	img {
		margin: 50px auto 0;
		max-width: 600px;
		width: 100%;
		display: block;
	}
	@media (max-width: 1024px) {
		margin: 40px 0;
	}

	h2 {
		color: #fff;
	}
`;
export const TokenomicsHeader = styled(Grid)`
	font-size: 14px;
	color: #fff;

	& > div {
		text-align: left;
		&:not(:first-child) {
			text-align: center;
		}
		padding: 10px 0;
	}
	@media (max-width: 1024px) {
		${({ mobileDisable }) => !mobileDisable && 'display: none'};
	}
`;
export const TokenomicsRow = styled(Grid)`
	font-size: 12px;
	border-top: 1px solid #2a2a2a;
	& > div {
		padding: 10px 0;
		height: 100%;
		display: flex;
		white-space: wrap;
		flex-direction: column;
		&:first-child,
		&:last-child {
			text-align: left;
		}

		a {
			word-break: break-all;
		}
	}
`;
export const TokenUtilityWrap = styled.div`
	margin: 100px 0;
	h2 {
		text-align: center;
		& + p {
			margin: 10px auto 50px;
			max-width: 600px;
			color: var(--subtext);
		}
	}
	img {
		width: 70%;
		margin-left: auto;
	}
	& > div > div {
		border-radius: 10px;
		background: var(--lbg);
		${DivBase};
		&:nth-child(1) {
			grid-column: 1 / 3;
			grid-row: 1 / 3;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		&:nth-child(3) {
			grid-column: 5 / 7;
			grid-row: 1 / 2;
		}
		&:nth-child(2) {
			grid-column: 3 / 5;
			grid-row: 1 / 2;
		}
		&:nth-child(4) {
			grid-column: 3 / 5;
			grid-row: 2 / 3;
		}
		&:nth-child(5) {
			grid-column: 5 / 7;
			grid-row: 2 / 3;
		}
		h4 {
			margin-bottom: 10px;
		}
		p {
			color: var(--subtext);
			font-size: 16px;
		}
	}
	@media (max-width: 1024px) {
		margin: 80px 0 40px;
		img {
			max-width: 150px;
		}
	}
`;

export const TokenomicsCandle = styled.div`
	@media screen and (max-width: 500px) {
        display: flex;
        align-items: center;
		span,
		p {
			text-orientation: mixed;
			writing-mode: vertical-rl;
			text-align: center;
		}

        & > div {
            width: 100%;
        }
	}

	span,
	p {
		text-align: left;
	}
	min-height: 273px;
	display: flex;
	flex-direction: column;
	justify-content: end;
	span {
		display: block;
		font-size: 30px;
		font-weight: 500;
		color: #fff;
	}
	p {
		font-size: 14px;
		margin: 10px 0;
	}
	div {
		border-radius: 10px;
		background: linear-gradient(
			180deg,
			rgba(37, 64, 30, 0.9) 3.5%,
			rgba(5, 5, 5, 0) 100%
		);
		transition: height 3s;
		&:hover {
			background: linear-gradient(
				180deg,
				rgba(37, 64, 30, 0.9) 90%,
				rgba(5, 5, 5, 0) 100%
			);
		}
	}
	@media (max-width: 1024px) {
		span {
			font-size: 16px;
		}
		p {
			font-size: 12px;
		}
	}
`;

export const ArticlesWrap = styled.div`
	margin-top: 100px;
	h2 {
		margin-bottom: 50px;
	}

	& > div > div {
		padding: 30px 0;
		border-radius: 10px;
		background: var(--lbg);
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover {
			background: linear-gradient(
				180deg,
				rgba(37, 64, 30, 0.9) 3.5%,
				rgba(5, 5, 5, 0) 100%
			);
		}

		a {
			display: inline-block;
			max-width: 80%;
			padding: 40px;
			font-size: 25px;
			font-weight: bold;
			color: #a0a0a0;
		}

		img {
			max-width: 100%;
			max-height: 46px;
			filter: grayscale(1);
		}

		h4 {
			margin-bottom: 10px;
		}
	}
	@media (max-width: 1024px) {
		margin-top: 40px;
	}
`;
export const RoadmapWrap = styled.div`
	margin-top: 100px;
	img {
		display: block;
		margin-left: auto;
		margin-top: 20px;
	}
	@media (max-width: 1024px) {
		margin-top: 40px;
	}
`;
export const RoadmapBox = styled.div`
	font-size: 14px;
	border-radius: 10px;
	padding: 30px 20px;
	position: relative;
	background: ${({ active }) =>
		!active
			? 'var(--lbg)'
			: 'linear-gradient(180deg, rgba(47, 67, 41, 0.94) -7.88%, #152111 100%)'};
	${({ active }) =>
		active &&
		`
        &::before {
            content: "\\2713";
            position: absolute;
            top: 10px;
            right: 10px;
            height: 20px;
            width: 20px;
            color: var(--special);
            background: var(--dbg);
            border-radius: 50%;
            font-size: 14px;
            text-align: center;
            line-height: 20px;
        }
    `}
	label {
		display: block;
		margin-bottom: 10px;
	}
`;
export const CustomMenuItem = styled(MenuItem)`
	display: flex;
	align-items: center;
	img {
		margin-right: 5px;
	}
`;
export const LockedTokensWrap = styled(TokenomicsWrap)`
	h3 {
		margin-bottom: 20px;
	}
`;
export const VideoWrapper = styled.div`
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		object-fit: cover;
		z-index: -1;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5); // adjust the opacity as needed
		z-index: -1;
	}
`;
