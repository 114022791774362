import { createGlobalStyle } from "styled-components";

const reset = `
    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, 
    big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, 
    center, dl, dt, dd, ol, ul, li, form, label, caption, table, tbody, tfoot, thead, tr, th, td {
        margin: 0;
        padding: 0;
        border: 0;
    }
    img {
        border: 0;
        vertical-align: middle;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after, q:before, q:after {
        content: "";
    }
    a:focus {
        outline: 0;
        text-decoration: none;
    }
    article, aside, details, figcaption, figure, footer, header, main, nav, section, summary {
        display: block;
    }
    button:focus {
        outline: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance:textfield;
    }
`;
export const GlobalStyle = createGlobalStyle`
    ${reset}
    :root {
        ${({ isDarkMode }) =>
          isDarkMode
            ? `
            --text: #FFFFFF;
            --subtext: #FFF;
            --dbg: #0D0D0D;
            --lbg: #2B2A2A;
            --subbg: #161616;
            --altbg: #505050;
            --bodybg: #3b3b3b;
            --pblack: #FFFFFF;
            --pblacktext: #000000;
            --error: #E53935;
            --warn: #f9b026;
            --overlay: #000000ab;
            --chartgreen: #42A226;
            --label: #A9A9A9;
            --cardshadow: 0 5px 6px 0 rgba(0, 0, 0, 0.3);
            --button: radial-gradient(farthest-corner, #409e25, #306820);
            --button-disabled: radial-gradient(farthest-corner, #A9A9A9, #A9A9A9);
        `
            : `
            --text: #111111;
            --subtext: #7C7C7C;
            --dbg: #f7f7f7;
            --lbg: #f1f1f1;
            --subbg: #FFFFFF;
            --altbg: #e9e9e9;
            --border: #767D8D;
            --bodybg: #fbfbfb;
            --pblack: #111111;
            --pblacktext: #FFFFFF;
            --error: #E53935;
            --warn: #f9b026;
            --overlay: #000000cc;
            --chartgreen: #42A226;
            --label: #383838;
            --cardshadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
            --button: radial-gradient(farthest-corner, #409e25, #306820);
            --button-disabled: radial-gradient(farthest-corner, #A9A9A9, #A9A9A9);
        `}
       
        --active: #5FB64630;
        --special: #5FB646;
        --special2: #3237B6;
        --green: #419663;
        --red: #681A15;
        --poor: #D14040;
        --fair: #46B69B;
        --great: #466CB6;
        --grey: #777989;
        --warnbg: #FFA72666;

        --toastify-color-dark: #0D0D0D;
        --toastify-text-color-dark: #BBBBBB;
        --toastify-color-info: var(--dbg);
        --toastify-color-success: var(--special);
        --toastify-color-warning: var(--special);
        --toastify-color-error: var(--special);
        
        background: var(--lbg);
    }
    * {
        box-sizing: border-box;
        transition: background-color .25s ease-out;

        &::-webkit-scrollbar {
            width: 14px;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--special);
            border-radius: 12px;
            background-clip: content-box;
            border: 4px solid transparent;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: transparent;
        }
    }
    a {
        text-decoration: none;
        color: inherit;
    }
    html {
        /* overflow: auto; */
        scroll-behavior: smooth;
        background: #0d0d0d;
    }
    html, body {
        margin: 0;
        padding: 0;
    }
    body {
        font-family: 'Work Sans', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow-x: hidden;
        /* background: linear-gradient(60deg, var(--lbg), var(--bodybg), var(--lbg)); */
        /* background: var(--lbg); */
        width: 100vw;
    }

    input {
        font-family: 'Work Sans', sans-serif;
    }

    input:focus-visible {
        outline: none;
    }

    h1, h2 {
        text-transform: none;
        font-size: 25px;
        font-weight: 400;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 16px;
    }
    span, p {
        line-height: 1.2;
    }
    p {
        font-size: 16px;
    }
    span {
        font-size: 15px;
    }
    h1, h2, h3, h4 {
        line-height: 1.2;
        font-weight: 500;
    }
    .styledScrollbar {
        ::-webkit-scrollbar {
            width: 4px;
        }
        /* Track */
        ::-webkit-scrollbar-track {
            background: rgba(0,0,0,0.1);
        }
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: var(--special);
        }
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: var(--special);
        }
    }
    // React tostify style changes
    .Toastify__toast {
        border-radius: 20px 20px 0 0;
    }
    .MuiTooltip {
        &-tooltip {
            background-color: var(--text) !important;
            padding: 15px 15px !important;
            color: var(--dbg) !important;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
            text-align: center;
        }

        &-arrow {
            color: var(--text) !important;
        }
    }
`;
