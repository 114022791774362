import { IconButton, Tooltip } from '@material-ui/core';
import { ReferralInputWrap, ReferralWrap } from './styled';
import { ReactComponent as IconCopy } from '../assets/copy.svg';
import { useState } from 'react';
import { useConnectWallet } from '@newm.io/cardano-dapp-wallet-connector';

const ReferralSection = ({ setWalletModalOpen, data, info }) => {
	const referralLinks = data?.referral ? data.referral.codes : [0];
	// const purchases = data?.referral ? data.referral.purchases : [];
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const { isConnected } = useConnectWallet();

	const handleCopy = (string, id) => {
		navigator.clipboard.writeText(string);
		setTooltipOpen(id);
		setTimeout(() => {
			setTooltipOpen(false);
		}, 1000);
	};

	return (
		<ReferralWrap>
			<h1>
				Become CERRA Public Sale Referral and Earn{' '}
				<span>10% CERRA rewards</span>
			</h1>
			{referralLinks.map((item, key) => {
				if (!info) return null;
				const tier = info?.referral_tiers?.[item?.tier];

				return (
					<div key={key}>
						{item !== 0 && (
							<h2>
								{tier?.currency} referral ({tier?.percent || 0}
								%):
							</h2>
						)}
						<ReferralInputWrap>
							<div>
								<input
									placeholder='Referral code'
									value={
										isConnected && item?.referral_code
											? `https://sale.cerra.io?ref=${item.referral_code}`
											: 'Please connect your wallet'
									}
									disabled
								/>
							</div>
							{isConnected && (
								<Tooltip
									title='Copied!'
									open={tooltipOpen === key}
								>
									<IconButton
										onClick={() =>
											handleCopy(
												`https://sale.cerra.io?ref=${item.referral_code}`,
												key
											)
										}
									>
										<IconCopy
											style={{
												cursor: 'pointer',
											}}
										/>
									</IconButton>
								</Tooltip>
							)}
						</ReferralInputWrap>
					</div>
				);
			})}
		</ReferralWrap>
	);
};

export default ReferralSection;
