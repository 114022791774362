import styled from 'styled-components';

import { ReactComponent as IconDisco } from '../assets/icons/discord.svg'
import { ReactComponent as IconGitbook } from '../assets/icons/gitbook.svg'
import { ReactComponent as Logo } from '../assets/icons/logo-footer.svg'
import { ReactComponent as IconMedium } from '../assets/icons/medium.svg'
import { ReactComponent as IconTw } from '../assets/icons/tw.svg'
import { ReactComponent as VyraIcon } from '../assets/icons/vyra.svg'

const Flex = styled.div`
    display: flex;
    ${({ align }) => (align ? `align-items: ${align}` : '')};
    ${({ justify }) => (justify ? `justify-content: ${justify}` : '')};
    ${({ spacing }) => (spacing ? `gap: ${spacing}px` : '')};
    ${({ wrap }) => (wrap ? `flex-wrap: wrap` : '')};
    width: ${({ full }) => (full ? '100%' : 'auto')};
    flex-direction: ${({ column }) => (column ? 'column' : 'row')};
    ${({ grow }) => grow && `flex-grow: ${grow}`};
    @media (max-width: ${({ sm, xs, md }) => (xs ? '400px' : sm ? '767px' : md ? '1024px' : 0)}) {
        display: block;
    }
`
const Grid = styled.div`
    @media (min-width: ${({ disableAt }) => (disableAt ? `${disableAt}px` : '1024px')}) {
        display: grid;
        grid-template-columns: ${({ col, colExact }) => colExact || (col ? `repeat(${col}, minmax(0, 1fr))` : 'repeat(2, minmax(0, 1fr))')};
        grid-gap: ${({ gap }) => (gap ? `${gap}px` : '20px')};
        align-items: ${({ align }) => align || 'start'};
    }
    ${({ colXs, colXsExact }) =>
        colXs || colXsExact
            ? `
        @media (max-width: 600px) {
            grid-template-columns: ${colXsExact || (colXs ? `repeat(${colXs}, minmax(0, 1fr))` : 'repeat(2, minmax(0, 1fr))')}};
        }
    `
            : ''}
    ${({ colXl, colXlExact }) =>
        colXl || colXlExact
            ? `
        @media (min-width: 1600px) {
            grid-template-columns: ${colXlExact || (colXl ? `repeat(${colXl}, minmax(0, 1fr))` : 'repeat(2, minmax(0, 1fr))')}};
        }
    `
            : ''}
    @media (max-width: ${({ disableAt }) => (disableAt ? `${disableAt}px` : '1024px')}) {
        ${({ mobileGap }) =>
            mobileGap &&
            `
                & > * + * {
                    margin-top: ${mobileGap}px;
                }
        `};
        ${({ gapMobile }) =>
            gapMobile &&
            `
            & > * {
                margin-top: ${gapMobile}px;
            }
        `};
    }
`

const Container = styled.div`
    max-width: ${({ width }) => width || '1200px'};
    padding: 0 20px;
    margin: 0 auto;
`
const FooterWrap = styled.footer`
    background: #000;
    padding: 50px 0;
    a,
    p {
        font-size: 14px;
        color: #fff;
    }
    p {
        margin-top: 50px;
        text-align: center;
    }
    svg path {
        fill: #fff;
    }
    @media (max-width: 1024px) {
        * {
            text-align: center;
            justify-content: center;
        }
        & > div > div > * + * {
            margin-top: 20px;
        }
        a {
            font-size: 12px;
        }
    }
`

const Footer = () => (
    <FooterWrap>
        <Container>
            <Grid colExact="minmax(0, 1fr) minmax(0, 3fr) minmax(0, 1fr)">
                <Logo />
                <Flex justify="center" style={{"flexWrap": "wrap"}} spacing={15}>
                    <a rel="noreferrer" target="_blank" href="https://cerra.io/#about" >
                        Home
                    </a>
                    <a rel="noreferrer" target="_blank" href="https://cerra.io/#product" >
                        Products
                    </a>
                    <a rel="noreferrer" target="_blank" href="https://cerra.io/#tokenomics" >
                        Tokenomics
                    </a>
                    <a rel="noreferrer" target="_blank" href="https://cerra.io/#utility" >
                        Token Utility
                    </a>
                    <a rel="noreferrer" target="_blank" href="https://cerra.io/#roadmap" >
                        Roadmap
                    </a>
                    <a href="https://documentation.cerra.io/cerra_io/" target="_blank" rel="noreferrer">
                        Documentation
                    </a>
                    <a href="mailto: info@cerra.io">Contact us</a>
                </Flex>
                <Flex align="center" justify="end" spacing={20}>
                    <a href="https://discord.gg/NAhSpAvg4a" title="Discord" target="_blank" rel="noreferrer">
                        <IconDisco />
                    </a>
                    <a href="https://twitter.com/Cerra_io" title="X" target="_blank" rel="noreferrer">
                        <IconTw />
                    </a>
                    <a href="https://medium.com/@cerra_io" title="Medium" target="_blank" rel="noreferrer">
                        <IconMedium />
                    </a>
                    <a href="https://documentation.cerra.io/cerra_io/" title="Git" target="_blank" rel="noreferrer">
                        <IconGitbook />
                    </a>
                    <a href="https://vyra.io/cerra_io/" title="Vyra" target="_blank" rel="noreferrer">
                        <VyraIcon />
                    </a>
                </Flex>
            </Grid>
            <p>© cerra.io {new Date().getFullYear()}</p>
        </Container>
    </FooterWrap>
)

export default Footer
