import PublicSale from "./PublicSale/PublicSale";
import { GlobalStyle } from "./globalStyled";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { handleGeneralSaleStats, handleGetWalletInfo } from "./api/api";
import { useConnectWallet } from "@newm.io/cardano-dapp-wallet-connector";
import * as S from "@emurgo/cardano-serialization-lib-asmjs";
import Header from "./components/Header";

const toastConfig = {
  theme: "dark",
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
};

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const ref = urlParams.get("ref");
  if (ref && ref.length > 3 && ref.length < 16) {
    localStorage.setItem("referral-code", ref);
  }

  const { isConnected, wallet } = useConnectWallet();
  const [generalInfo, setGeneralInfo] = useState({});
  const [generalWalletInfo, setGeneralWalletInfo] = useState({});

  useEffect(() => {
    let interval;
    (async () => {
      setGeneralInfo(await handleGeneralSaleStats());
      interval = setInterval(async () => {
        setGeneralInfo(await handleGeneralSaleStats());
      }, window.INFO_REFRESH_INTERVAL);
    })();
    return () => interval && clearInterval(interval);
  }, []);

  useEffect(() => {
    let interval;
    (async () => {
      if (isConnected && wallet) {
        let address = await wallet.getChangeAddress();
        address = Array.isArray(address) ? address[0] : address;
        address = S.Address.from_bytes(Buffer.from(address, "hex")).to_bech32();
        setGeneralWalletInfo(await handleGetWalletInfo(address));
        interval = setInterval(async () => {
          setGeneralWalletInfo(await handleGetWalletInfo(address));
        }, window.INFO_REFRESH_INTERVAL);
      } else {
        setGeneralWalletInfo({});
      }
    })();
    return () => interval && clearInterval(interval);
  }, [isConnected, wallet]);

  return (
    <>
      <GlobalStyle isDarkMode={true} animations={false} />
      <div className="App">
        <Header />
        <PublicSale
          info={generalInfo}
          walletInfo={generalWalletInfo}
          wallet={wallet}
        />
        <ToastContainer {...toastConfig} />
      </div>
    </>
  );
}

export default App;
