import { useState } from 'react';
import Button from '../components/Button';
import { Lucid, Blockfrost } from 'lucid-cardano';
import {
	BuyFormButton,
	BuyFormWrap,
	BuyInput,
	SalePrompt,
	SalePromptWrap,
} from './styled';
import BigNumber from 'bignumber.js';
import { toastSuccess, toastError } from '../components/toast';
import { AdaImage, CerraImage } from './utils';
import Countdown from '../components/Countdown';

const BuyForm = ({
	isConnected,
	setWalletModalOpen,
	stats,
	walletInfo,
	wallet,
}) => {
	const [amount, setAmount] = useState('');
	const [receivedToken, setReceivedToken] = useState('');
	const [refCode, setRefCode] = useState(
		localStorage.getItem('referral-code')
	);

	// const isMobile =
	// 	/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
	// 		navigator.userAgent
	// 	);

	const submitTx = async () => {
		const ref = localStorage.getItem('referral-code');
		let codeFound = false;
		for (const referralCode of walletInfo.referral.codes) {
			if (referralCode.referral_code === ref) {
				codeFound = true;
			}
		}

		if (codeFound) {
			toastError(
				'Using your own referral link to make the purchase is not possible, please use a different referral code or make a purchase without one'
			);
			localStorage.removeItem('referral-code');
			setRefCode(undefined);
			setAmount(BigNumber(0));
			setReceivedToken(BigNumber(0));
			window.history.pushState(
				{},
				document.title,
				window.location.pathname
			);
			return;
		}

		try {
			const lucid = await Lucid.new(
				new Blockfrost(window.BLOCKFROST_URL, window.BLOCKFROST_KEY),
				window.NETWORK
			);

			let walletName = wallet.name.toLowerCase()
			if (walletName === 'flint wallet') {
				walletName = 'flint'
			} else if (walletName === 'typhon wallet') {
				walletName = 'typhoncip30'
			} else if (walletName === 'begin wallet') {
				walletName = 'begin'
			}

			const api = await window.cardano[
				walletName
			].enable();
			lucid.selectWallet(api);

			// eslint-disable-next-line no-undef
			const ada = BigInt(amount * Math.pow(10, 6));

			let tx = lucid.newTx();
			tx = tx.payToAddress('addr1q8c7wlwymkcj7t7d9mphf647pz23x2dzvygsm7cu8wmk4qjh90fs284td2ktnratlg794chwsxuxvzn74aq6phfzr2rq9ex739', { lovelace: ada });

			if (ref && ref.length > 3 && ref.length < 16) {
				tx = tx.attachMetadata(674, { msg: ref });
			}

			tx = await tx.complete();
			const signedTx = await tx.sign().complete();
			const txHash = await signedTx.submit();
			console.log(`Success! Tx hash: ${txHash}`);
			toastSuccess(`Success! Tx hash: ${txHash}`);
		} catch (e) {
			console.log(e);
			toastError(`Error: ${e.info || e}`);
		}
	};

	const start = new Date(window.SALE_START_UTC).getTime();
	const end = new Date(window.SALE_END_UTC).getTime();
	const now = Date.now();

	if (start > now || end < now) {
		return (
			<SalePromptWrap>
				<SalePrompt>Sale is currently not active</SalePrompt>
				<Countdown
					startDate={window.SALE_START_UTC}
					endDate={window.SALE_END_UTC}
				/>
			</SalePromptWrap>
		);
	}

	// if (isMobile) {
	// 	return (
	// 		<SalePrompt>Please use desktop browser to participate</SalePrompt>
	// 	);
	// }

	return (
		<div>
			<BuyFormWrap $mobileGap={20} $col={2} $disableAt={1024}>
				<div>
					<h3>Buy Cerra Token</h3>
					<BuyInput>
						<span>
							ADA <AdaImage height={30} />
						</span>
						<input
							type='number'
							value={amount}
							placeholder={0}
							min={0}
							onChange={(e) => {
								setAmount(
									BigNumber(e.target.value).decimalPlaces(6)
								);
								setReceivedToken(
									BigNumber(e.target.value)
										.dividedBy(
											BigNumber(stats.token_per_one_ADA)
										)
										.decimalPlaces(
											parseInt(
												window.SOLD_TOKEN.metadata
													.metadata_decimals
											)
										)
								);
							}}
						/>
					</BuyInput>
				</div>
				<div>
					<h3>Estimated Cerra amount</h3>
					<BuyInput>
						<span>
							CERRA <CerraImage height={30} />
						</span>
						<input
							type='number'
							value={receivedToken}
							placeholder={0}
							min={0}
							disabled
							onChange={(e) => {
								setAmount(
									BigNumber(e.target.value).decimalPlaces(6)
								);
								setReceivedToken(
									BigNumber(e.target.value)
										.dividedBy(
											BigNumber(stats.token_per_one_ADA)
										)
										.decimalPlaces(
											parseInt(
												window.SOLD_TOKEN.metadata
													.metadata_decimals
											)
										)
								);
							}}
						/>
					</BuyInput>
				</div>
			</BuyFormWrap>
			<BuyFormButton>
				{refCode && (
					<BuyInput>
						<span>Referral code:</span>
						<input
							disabled
							value={refCode ?? ''}
							label='Referral code'
						/>
					</BuyInput>
				)}
				<Button
					size='large'
					color='primary'
					onClick={() =>
						isConnected ? submitTx() : setWalletModalOpen(true)
					}
				>
					{isConnected ? 'Buy with ADA' : 'Connect wallet'}
				</Button>
			</BuyFormButton>
		</div>
	);
};

export default BuyForm;
