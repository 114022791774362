import { ThemeProvider, createTheme } from "@material-ui/core/styles";
const Constants = {
  PRIMARY: "#5FB646",
};

const theme = createTheme({
  palette: {
    primary: {
      main: Constants.PRIMARY,
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        color: "var(--text)",
        paddingLeft: 13,
        height: 26,
        "&$focused": {
          color: "var(--text)",
        },
        "&.Mui-disabled": {
          cursor: "not-allowed",
        },
      },
      root: {
        fontFamily: "'Work Sans', sans-serif;",
        "&&&:after": {
          borderColor: "var(--special)",
        },
        "&&&&:before": {
          borderColor: "var(--border)",
        },
      },
    },
    MuiTextField: {
      root: {
        // marginTop: 30,
      },
    },
    MuiOutlinedInput: {
      root: {
        background: "var(--lbg)",
        borderColor: "var(--lbg)",
        borderRadius: 20,
        border: 0,
        "&$focused $notchedOutline": {
          borderColor: "var(--special)",
          borderWidth: 1,
        },
        "&$disabled $notchedOutline": {
          borderColor: "var(--lbg)",
        },
      },
      notchedOutline: {
        border: 0,
        "& span": {
          display: "none",
        },
      },
      input: {
        padding: "11px 14px",
        "&::placeholder": {
          color: "var(--grey)",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&.Mui-disabled": {
          background: "#252525",
        },
      },
    },
    PrivateNotchedOutline: {
      root: {
        top: 0,
      },
      legendLabelled: {
        display: "none",
      },
    },
    MuiPopover: {
      paper: {
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        /* Track */
        "&::-webkit-scrollbar-track": {
          background: "#212121",
        },
        /* Handle */
        "&::-webkit-scrollbar-thumb": {
          background: "var(--special)",
        },
        /* Handle on hover */
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#3857ED",
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: "'Work Sans', sans-serif;",
        color: "var(--text)",
        "&$focused": {
          color: "var(--text)",
        },
        "&.Mui-disabled": {
          color: "var(--subtext)",
        },
      },
    },
    MuiInputLabel: {
      shrink: false,
      outlined: {
        transform: "none !important",
        color: "var(--text)",
        position: "absolute",
        left: 10,
        top: -20,
      },
    },
    // MuiInputLabel: {
    //     shrink: {
    //         transform: 'translate(10px, 8px) scale(0.75)',
    //     },
    // },
    // MuiFormControl: {
    //     root: {
    //         background: 'var(--border)',
    //         borderRadius: 10,
    //     },
    // },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      label: {
        padding: "10px 20px",
        fontFamily: "'Work Sans', sans-serif;",
        textTransform: "none",
        letterSpacing: 1.1,
        fontSize: 15,
        fontWeight: 400,
        wordBreak: "normal",
      },
      text: {
        padding: 0,
        // border: '1px solid var(--special)',
      },
      textPrimary: {
        background: "var(--button)",
        color: "#fff",
        transition: "all 0.3s ease",
        "&:hover": {
          opacity: 0.8,
          background: "var(--button)",
        },
        "&:disabled": {
          backgroundColor: "#6D6D6D",
          border: "none",
          color: "#000000",
          cursor: "not-allowed",
          pointerEvents: "all",
          "&:hover": {
            backgroundColor: "#6D6D6D",
            opacity: 0.9,
          },
        },
      },
      textSecondary: {
        backgroundColor: "transparent",
        color: "var(--special)",
        "&:hover, &:focus": {
          opacity: 0.8,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: "var(--text)",
      },
    },
    MuiPaper: {
      root: {
        color: "var(--text)",
        backgroundColor: "transparent",
      },
    },
    MuiDialog: {
      paper: {
        overflowY: "hidden",
        overflowX: "hidden",
      },
    },
    MuiTab: {
      root: {
        minWidth: 0,
        fontFamily: "inherit",
        fontSize: 15,
        textTransform: "none",
        borderRadius: "20px 20px 0 0",
        "@media (min-width: 0px)": {
          minWidth: 0,
        },
      },
      wrapper: {
        fontSize: 15,
      },
      textColorInherit: {
        "&.Mui-selected": {
          // backgroundColor: "var(--subbg)",
          color: "var(--text)",
        },
      },
    },
    MuiTabs: {
      scroller: {
        // backgroundColor: 'var(--subbg)',
        // padding: 7,
      },
      indicator: {
        display: "none",
      },
    },
    MuiCollapse: {
      root: {
        backgroundColor: "var(--lbg)",
      },
    },
    MuiAccordion: {
      root: {
        borderRadius: 20,
        overflow: "hidden",
        "&.Mui-expanded": {
          border: "1px solid var(--special)",
          margin: 0,
          marginTop: 5,
        },
        "& + div": {
          marginTop: 5,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: "0 0 0 10px",
        background: "var(--lbg)",
      },
      content: {
        fontSize: 21,
        fontWeight: 600,
      },
      expandIcon: {
        fill: "var(--special)",
      },
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: 10,
      },
      colorSecondary: {
        "&:hover": {
          backgroundColor: "#00f5150a",
        },
      },
    },
    MuiMenu: {
      list: {
        backgroundColor: "var(--lbg)",
      },
    },
    MuiRadio: {
      root: {
        color: "var(--text)",
      },
      colorSecondary: {
        "&.Mui-checked": {
          color: "var(--special)",
        },
      },
    },
    MuiSlider: {
      rail: {
        color: "var(--text)",
      },
      track: {
        color: "var(--special)",
      },
      markLabel: {
        color: "var(--text)",
      },
      markLabelActive: {
        color: "var(--special)",
      },
      thumbColorPrimary: {
        backroundColor: "var(--special)",
      },
    },
    MuiSelect: {
      root: {
        fontSize: 17,
      },
      icon: {
        color: "var(--text)",
      },
      outlined: {
        padding: "14px 32px 15px 14px",
      },
    },
    MuiFormHelperText: {
      root: {
        color: "var(--text)",
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: "var(--text)",
      },
    },
    MuiInputAdornment: {
      root: {
        color: "var(--text)",
        "& .MuiInputBase-root, & fieldset": {
          border: "none",
        },
        "& .MuiFormControl-root": {
          borderLeft: "1px solid var(--text)",
        },
        "& svg path": {
          fill: "var(--text)",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        fill: "var(--text)",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid #161616",
      },
    },
  },
});

const MuiTheme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default MuiTheme;
