import styled from 'styled-components';
import { Grid } from '../components/Common';
import { Dialog } from '@material-ui/core';

export const Wrap = styled.section`
	background: var(--dbg);
	& > div {
		max-width: 1260px;
		padding: 0 20px;
		margin: 0 auto;
	}
	p {
		color: var(--label);
	}
	h1,
	h2,
	h3,
	h4 {
		color: var(--text);
	}
`;

export const Hero = styled.div`
	padding: 100px 0;
	svg {
		max-width: 400px;
	}
	h1 {
		font-size: 50px;
		margin-bottom: 20px;
		& + p {
			margin-bottom: 30px;
		}
	}
	@media (min-width: 1200px) {
    padding: 60px 0;
		h1 + p {
			width: 70%;
		}
	}
`;

export const ChartInfoWrap = styled.div`
	height: auto !important;
  min-width: 190px;
	h4 {
		margin-bottom: 20px;

		span {
			color: var(--special);
			font-size: 45px;
			font-weight: 500;
		}
	}

  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

export const GoalProgress = styled.div`
	--animationcolor: #b2fd9d;
	@keyframes pulse {
		0% {
			background-color: #151515;
			transform: translateY(0px);
		}
		100% {
			background-color: var(--animationcolor);
			transform: translateY(-5px);
		}
	}
	display: flex;
	gap: 20px;
	margin-bottom: 30px;
  justify-content: flex-start;

  @media screen and (max-width: 600px) {
    justify-content: center;
  }
	& > div {
		text-align: center;

		& > div {
			height: 25px;
			width: 25px;
			border-radius: 50%;
			margin-bottom: 10px;
		}
		span {
			color: var(--label);
			font-size: 12px;
		}
		&:first-of-type > div {
			background: ${({ level }) => (level >= 1 ? '#b2fd9d' : '#151515')};
			${({ level }) =>
				level === 0 &&
				`
          --animationcolor: #b2fd9d;
          animation: pulse 2s infinite alternate ease;
        `};
		}
		&:nth-of-type(2) > div {
			background: ${({ level }) => (level >= 2 ? '#74d359' : '#151515')};
			${({ level }) =>
				level === 1 &&
				`
          --animationcolor: #74d359;
          animation: pulse 2s infinite alternate ease;
        `};
		}
		&:nth-of-type(3) > div {
			background: ${({ level }) => (level >= 3 ? '#397c27' : '#151515')};
			${({ level }) =>
				level === 2 &&
				`
          --animationcolor: #397c27;
          animation: pulse 2s infinite alternate ease;
        `};
		}
		&:last-of-type > div {
			background: ${({ level }) => (level >= 4 ? '#295e1a' : '#151515')};
			${({ level }) =>
				level === 3 &&
				`
          --animationcolor: #295e1a;
          animation: pulse 2s infinite alternate ease;
        `};
		}
	}
`;

export const TimerWrap = styled.div`
	p {
		color: var(--special);
		font-size: 24px;
		font-weight: 500;
	}
	span {
		font-size: 12px;
		color: var(--label);
	}
`;

export const InfoWrap = styled(Grid)`
	margin: 60px 0;
	& > div {
		background: linear-gradient(
			180deg,
			#161616 -4.1%,
			rgba(5, 5, 5, 0) 100%
		);
		padding: 40px 10px 70px;
		border-radius: 10px;
		span,
		p {
			text-align: center;
		}
		span {
			display: block;
			color: var(--label);
		}
		p {
			font-size: 30px;
			color: var(--text);
			margin-bottom: 10px;
		}
	}
`;

export const BuyFormWrap = styled(Grid)`
	h3 {
		font-size: 30px;
		margin-bottom: 20px;
	}
`;

export const BuyFormButton = styled.div`
	margin-top: 40px;
	width: 100%;
	margin: 40px auto 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: center; */
	gap: 40px;

	& > button {
		max-width: 200px;
	}
`;

export const ReferralWrap = styled.section`
	max-width: 1260px;
	padding: 40px;
	border-radius: 10px;
	margin: 120px 0 80px;
	background: linear-gradient(180deg, #161616 -4.1%, rgba(5, 5, 5, 0) 100%);
	display: flex;
	flex-direction: column;
	gap: 20px;

	h1 {
		font-size: 30px;
		margin-bottom: 30px;
	}
	h1 span {
		color: var(--special);
		font-size: inherit;
	}

	h2 {
		font-size: 16px;
		margin-bottom: 16px;
	}

	@media (min-width: 1024px) {
		h1 {
			width: 50%;
		}
	}
	@media (max-width: 767px) {
		padding: 20px;
	}
`;

export const ReferralInputWrap = styled.div`
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	display: flex;

	@media (min-width: 767px) {
		display: flex;
		align-items: center;
		gap: 20px;
		& > div {
			flex: 1;
		}
	}
	width: 100%;

	& > div {
		flex: 1;
	}
	@media (max-width: 767px) {
		& > * {
			/* width: 100%; */
		}
	}

	input {
		background-color: transparent;
		border: none;
		font-size: 14px;
		padding: 15px;
		flex: 1;
		color: #fff;
		width: 100%;
	}
`;

export const DetailsModal = styled(Dialog)`
	backdrop-filter: blur(10px);

	.MuiDialog-paper {
		background: var(--dbg);
		padding: 30px;
		border-radius: 10px;

		& > svg {
			position: absolute;
			right: 20px;
			top: 20px;
			cursor: pointer;
			color: var(--text);
			transition: all 0.3s ease;

			&:hover {
				color: var(--special);
			}
		}
	}
	h1 {
		margin-bottom: 50px;
	}
	.MuiTab-root {
		border-bottom: 1px solid transparent;
	}
	.MuiTab-textColorInherit.Mui-selected {
		color: var(--special);
		border-color: var(--special);
	}
`;

export const TabWrap = styled.div`
	margin-top: 50px;
	h4 {
		color: var(--text);
	}
	p {
		margin-top: 10px;
		color: var(--label);
	}
`;

export const ParticipationWrap = styled.div`
	& {
		padding: 20px !important;
	}
	h1 {
		margin: 20px 0;
	}
	h3 {
		margin: 40px 0 20px;
	}
	img {
		margin-right: 5px;
	}
`;

export const BuyInput = styled.div`
	display: block;
	background-color: #1c1c1c;
	width: 100%;
	display: flex;
	border-radius: 10px;
	align-items: center;
	overflow: hidden;

	span {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
		gap: 10px;
		color: #fff;
		background-color: #393939;
		padding: 25px 35px;
		font-size: 22px;
		font-weight: 500;
	}

	input {
		border: none;
		font-size: 25px;
		font-weight: 500;
		color: #fff;
		padding: 25px;
		background-color: transparent;
		flex: 1;
	}
`;

export const ChartAndInfoWrap = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    gap: 20px;
  }
`

export const SalePrompt = styled.p`
  text-align: center;
  font-size: 24px;
  color: var(--label) !important;
`
export const SalePromptWrap = styled(TimerWrap)`
  text-align: center;
  font-size: 24px;
  /* color: var(--special); */
  background: linear-gradient(180deg, #161616 -4.1%, rgba(5, 5, 5, 0) 100%);
  padding: 40px 10px;
  border-radius: 10px;

  & > p {
    padding-bottom: 20px;
  }

  & > div {

    display: flex;
    flex-direction: column;
  }
`
export const GradientBlock = styled.div`
		background: linear-gradient(
			180deg,
			#161616 -4.1%,
			rgba(5, 5, 5, 0) 100%
		);
		padding: 20px;
		border-radius: 10px;

		${({spread}) => (spread && `
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		`)}
`

export const SummaryWrapper = styled.div`
	font-family: 'Work Sans', sans-serif;

	& > div:first-child {
		margin-bottom: 20px;
	}

	p {
		color: #fff;
	}
`

export const SummaryBlocks = styled.div`
	display: flex;
	gap: 10px;
	margin-top: 20px;
	font-size: 14px;
	flex-wrap: wrap;

	& > div {
		flex: 1;
		min-width: 270px;

		@media screen and (max-width: 500px) {
			min-width: 190px;
			padding: 10px;
		}
	}
`
export const SummaryContent = styled.div`
	margin-top: 40px;
	font-size: 16px;

	b {
		color: var(--special);
		font-weight: normal;
	}

	.title {
		margin-bottom: 20px;
	}

	span {
		font-size: 16px;

		span {
			color: #fff;
		}
	}
`
