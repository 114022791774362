import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  ${({ align }) => (align ? `align-items: ${align}` : "")};
  ${({ justify }) => (justify ? `justify-content: ${justify}` : "")};
  width: ${({ full }) => (full ? "100%" : "auto")};
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  ${({ grow }) => grow && `flex-grow: ${grow}`};
  gap: ${({ gap }) => (gap ? `${gap}px` : "0")};
  @media (max-width: ${({ sm, xs, md }) => xs ? "400px" : sm ? "1200px" : md ? "1200px" : 0}) {
    display: block;
  }
`;

const CurrencyMetaImageContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const generateColor = (value, contrast, reverse, lighten) => {
  if (!value || typeof value !== "string") return "rgb(0, 0, 0)";
  const symbols = "abcdefghijklmnopqrstuvwxyz";
  const arr = value.split("").map((i) => i.toLocaleLowerCase());
  const mArr = [...arr, ...arr, ...arr].slice(0, 3);
  const cArr = mArr.map((i) => {
    const code =
      parseInt((Math.abs(symbols.indexOf(i)) / symbols.length) * 255) +
      (lighten ? 80 : 0);
    const vCode = code > 255 ? 255 : code;
    return reverse ? 255 - vCode : vCode;
  });
  if (contrast) {
    return cArr.reduce((a, b) => a + b, 0) > 255
      ? "rgb(22, 22, 22)"
      : "rgb(233, 233, 233)";
  }
  return `rgb(${cArr.join(", ")})`;
};

const CurrencyMetaImage = ({
  token,
  height = 30,
  margin = "0 5px 0 0",
  ...rest
}) => {
  const base64 =
    token?.metadata?.metadata_logo ||
    token?.metadata_logo ||
    token?.metadata?.logo ||
    token?.logo;
  const name =
    token?.ticker ||
    token?.coin ||
    token?.metadata?.metadata_ticker ||
    token?.metadata_ticker;

  const src =
    name === "ADA" || !name || !token
      ? `https://res.cloudinary.com/a8234620/image/upload/${
          height ? `h_${height}/` : ""
        }logos/ada.png`
      : base64
      ? `data:image/png;base64, ${base64}`
      : typeof token === "string"
      ? `data:image/png;base64, ${token}`
      : undefined;

  const SvgIcon = () => {
    const gradientId = `${name}-${parseInt(Math.random() * 10000000)}`;
    const xName = name.replace(/^t/g, "");
    return (
      <svg viewBox="0 0 30 30" width={height} height={height}>
        <defs>
          <linearGradient x1={1} y1={0} x2={0} y2={1} id={gradientId}>
            <stop
              offset="0%"
              stopColor={generateColor(xName, false, false, true)}
            />
            <stop offset="100%" stopColor={generateColor(xName)} />
          </linearGradient>
        </defs>
        <circle cx="15" cy="15" r="15" fill={`url(#${gradientId})`} />

        <text
          x="50%"
          y="53%"
          fill={generateColor(xName, true)}
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"9px"}
          fontWeight={"bold"}
        >
          {xName.slice(0, 4).toUpperCase()}
        </text>
      </svg>
    );
  };
  return (
    <CurrencyMetaImageContainer
      style={{ width: `${height}px`, height: `${height}px`, margin }}
    >
      {!!src || !name ? (
        <img
          src={src}
          style={{ borderRadius: 50 }}
          alt={
            token?.metadata?.metadata_ticker ||
            token?.metadata_ticker ||
            "coin logo"
          }
          height={height}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = `https://res.cloudinary.com/a8234620/image/upload/${
              height ? `h_${height}/` : ""
            }logos/ada.png`;
          }}
          {...rest}
        />
      ) : (
        <SvgIcon />
      )}
    </CurrencyMetaImageContainer>
  );
};
const CurrencyImage = ({ currency, url, height = 30, ...rest }) => {
  let fullUrl = "";
  const CloudVersion = "a8234620";
  if (
    height &&
    url &&
    url.includes(`https://res.cloudinary.com/${CloudVersion}`)
  ) {
    const SplitUrl = url.split(`${CloudVersion}/image/upload/`);
    fullUrl = `${SplitUrl[0]}${CloudVersion}/image/upload/h_${height}/${SplitUrl[1]}`;
  }
  return (
    <img
      src={
        fullUrl ||
        url ||
        `https://res.cloudinary.com/a8234620/image/upload/${
          height ? `h_${height}/` : ""
        }v1/logos/${
          typeof currency === "object"
            ? currency.code.toLowerCase()
            : currency.toLowerCase()
        }.png`
      }
      style={{ borderRadius: 50 }}
      alt="coin logo"
      height={height}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = `https://res.cloudinary.com/a8234620/image/upload/${
          height ? `h_${height}/` : ""
        }logos/cerra.png`;
      }}
      {...rest}
    />
  );
};
const ImagesWrap = styled.span`
  & > img:last-child {
    margin-left: ${({ height }) => (height ? `-${height / 2.5}px` : "-5px")};
  }
`;
const MarketImages = ({ market, height, ...rest }) => (
  <ImagesWrap {...rest} height={height}>
    <CurrencyMetaImage height={height} token={market.asset_a} />
    <CurrencyMetaImage height={height} token={market.asset_b} />
  </ImagesWrap>
);

const SimpleLink = ({ href, children, ...rest }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
    {children}
  </a>
);
const Container = styled.div`
  max-width: ${({ width }) => (width ? `${width}px` : "1280px")};
  padding: 0 20px;
  margin: 0 auto;
`;
const Grid = styled.div`
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: ${({ $col, $colExact }) => {
      return (
        $colExact ||
        ($col ? `repeat(${$col}, minmax(0, 1fr))` : "repeat(2, minmax(0, 1fr))")
      );
    }};
    grid-gap: ${({ $gap }) => ($gap ? `${$gap}px` : "20px")};
    align-items: ${({ $align }) => $align || "start"};
    justify-content: ${({ $justify }) => $justify || "start"};

    > div {
      height: 100%;
    }
  }
  ${({ $colXs, $colXsExact }) =>
    $colXs || $colXsExact
      ? `
        @media (max-width: 600px) {
            grid-template-columns: ${
              $colXsExact ||
              ($colXs
                ? `repeat(${$colXs}, minmax(0, 1fr))`
                : "repeat(2, minmax(0, 1fr))")
            }};
        }
    `
      : ""}
  ${({ $colXl, $colXlExact }) =>
    $colXl || $colXlExact
      ? `
        @media (min-width: 1600px) {
            grid-template-columns: ${
              $colXlExact ||
              ($colXl
                ? `repeat(${$colXl}, minmax(0, 1fr))`
                : "repeat(2, minmax(0, 1fr))")
            }};
        }
    `
      : ""}
    @media (max-width: ${({ $disableAt }) => $disableAt ? `${$disableAt}px` : "1200px"}) {
    ${({ $mobileGap }) =>
      $mobileGap &&
      `
                & > * + * {
                    margin-top: ${$mobileGap}px;
                }
        `};
    ${({ $gapMobile }) =>
      $gapMobile &&
      `
            & > * {
                margin-top: ${$gapMobile}px;
            }
        `};
  }
`;
const TextWrap = styled.div`
  h3 {
    font-weight: 600;
  }
  p {
    color: var(--subtext);
  }
  @media (max-width: 1200px) {
    h3 {
      font-size: 17px;
    }
  }
`;
const LogoTitle = ({ currency, market, url, height = 30 }) =>
  market ? (
    <Grid colExact="70px auto" gap={10} align="center" disableAt={1}>
      <Grid disableAt={1} gap={10}>
        <CurrencyMetaImage height={height} token={market.asset_a} />
        <CurrencyMetaImage height={height} token={market.asset_b} />
      </Grid>
      <TextWrap>
        <h4>
          {market?.asset_a?.metadata?.metadata_ticker} /{" "}
          {market?.asset_b?.metadata?.metadata_ticker}
        </h4>
      </TextWrap>
    </Grid>
  ) : (
    <Grid colExact={`${height}px auto`} gap={15} align="center" disableAt={1}>
      <CurrencyMetaImage height={height} token={currency} url={url} />
      <TextWrap>
        <h4 style={{ fontWeight: 600 }}>
          {currency?.metadata?.metadata_ticker?.toUpperCase() ||
            currency?.ticker}
        </h4>
        {/* <p style={{ color: 'var(--subtext)' }}>{ticker?.toUpperCase()}</p> */}
      </TextWrap>
    </Grid>
  );

const Input = styled.input`
  height: 44px;
  width: ${({ full }) => (full ? "100%" : "320px")};
  background-color: var(--border);
  border: none;
  padding: 0 20px;
  &:hover {
    cursor: pointer;
  }
`;
const InfoBlockWrap = styled.div`
  display: flex;
  ${({ horizontal }) => `
        flex-direction: ${horizontal ? "row" : "column"};
        align-items: ${horizontal ? "center" : "flex-start"};
        justify-content: ${horizontal ? "space-between" : "flex-start"};
        span {
            margin-right: ${horizontal ? "5px" : 0};
        }
    `}
  margin: ${({ margin }) => margin || 0};
  p {
    font-weight: 600;
    color: var(--subtext);
  }
`;
const InfoBlock = ({ horizontal, title, children, ...props }) => (
  <InfoBlockWrap {...props} horizontal={horizontal}>
    <span>{title}</span>
    <p>{children}</p>
  </InfoBlockWrap>
);

const Splitter = (val, splitter, number) => val.split(splitter)[number];

const Card = styled.div`
  background: ${({ background }) => background || "var(--subbg)"};
  padding: ${({ padding }) => `${padding || 20}px`};
  border-radius: 20px;
  box-shadow: var(--cardshadow);
  /* display: flex;
    justify-content: space-between;
    flex-direction: column; */
  @media (max-width: 1200px) {
    padding: 20px;
    width: 100%;
  }
`;
const GridAutoHeight = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text);
`;
const ExpandGridAutoHeight = styled(GridAutoHeight)`
  grid-column: 2 / 4;
  display: flex;
  flex-direction: column;
`;

const MainAccountWrapper = styled.div`
  background: var(--subbg);
  padding: 40px;
  border-radius: 20px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: var(--cardshadow);
`;
const InformationWrapper = styled.div`
  background: var(--subbg);
  padding: 40px;
  border-radius: 20px;
  overflow: hidden;
  flex-grow: 1;
  box-shadow: var(--cardshadow);
`;
const TopAssetsWrapper = styled.div`
  background: var(--subbg);
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 40px;
  flex-grow: 1;
  box-shadow: var(--cardshadow);
`;
const ChartTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
`;
const ChartTooltip = styled.div`
  background: var(--subbg);
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;
const WrapFullWidth = styled.div`
  @media (max-width: ${({ fullAt }) => fullAt || "1200px"}) {
    margin: 0 -20px;
    padding: ${({ padding }) => `0 ${padding || 0}px`};
  }
`;
const AddressLink = styled(SimpleLink)`
  color: var(--special);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 1200px) {
    white-space: normal;
  }
`;

export {
  Grid,
  Container,
  SimpleLink,
  MarketImages,
  CurrencyImage,
  Flex,
  LogoTitle,
  Input,
  InfoBlock,
  Splitter,
  Card,
  ChartTitle,
  ChartTooltip,
  WrapFullWidth,
  AddressLink,
  CurrencyMetaImage,
  MainAccountWrapper,
  InformationWrapper,
  TopAssetsWrapper,
  GridAutoHeight,
  ExpandGridAutoHeight,
};
