import { toastError } from '../components/toast'

export const HandleApi = async ({ query }) => {
    const params = { ...query }

    const result = await fetch(window.PHP_BACKEND_URL, {
        method: 'POST',
        body: JSON.stringify(params),
    })
        .then(response => response.json())
        .catch(e => {
            console.log('Fetch error: ', query?.endpoint, e)
        })

    if (result?.error) {
        const err = result.error
        toastError(`${err}`)
        return result
    }

    return result
}

export const handleGeneralSaleStats = async () => {
    const params = {
        endpoint: 'getPublicSaleStats',
    }
    try {
        return await HandleApi({ query: params })
    } catch (e) {
        console.log('Failed to getPublicSaleStats: ', e)
    }
    return false
}

export const handleGetWalletInfo = async (address) => {
    const params = {
        endpoint: 'getPublicSaleAddressInfo',
        user_address: address,
    }

    try {
        return await HandleApi({ query: params })
    } catch (e) {
        console.log('Failed to getStatistics: ', e)
    }
    return false
}
