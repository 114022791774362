import { default as B } from '@material-ui/core/Button';
import Spinner from './Spinner';
import styled from 'styled-components';

const ButtonStyled = styled(B)`
	position: relative;

	${({ custom, disabled }) =>
		custom === 'outline' && !disabled
			? `
                background: transparent !important;
                box-shadow: inset 0 0 0 2px var(--special);
                color: var(--text) !important; 
            `
			: ''}

	&:disabled,
    &:hover:disabled {
		background: var(--button-disabled) !important;
	}

	.btn-l {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateY(-50%) translateX(-50%);
	}

	.btn-c {
		&--loading {
			opacity: 0;
		}
	}
`;

const Button = ({
	label,
	children,
	loading,
	type = 'primary',
	spinnerColor,
	custom,
	...props
}) => (
	<ButtonStyled color={type} custom={custom} {...props}>
		{loading && (
			<div className='btn-l'>
				<Spinner color={spinnerColor} />
			</div>
		)}
		<div className={`btn-c${loading ? ' btn-c--loading' : ''}`}>
			{label || children}
		</div>
	</ButtonStyled>
);

export default Button;
