import {
	GradientBlock,
	SummaryBlocks,
	SummaryContent,
	SummaryWrapper,
} from './styled';

const Summary = () => {
	return (
		<SummaryWrapper>
			<div>
				<p>PRORATA CERRA PUBLIC SALE</p>
				<span>Duration 48 hours</span>
			</div>
			<GradientBlock>
				<p>Sale Details</p>
				<SummaryBlocks>
					<GradientBlock spread>
						<div>
							<p>Tokens Allocated</p>
							<span>10,000,000 CERRA</span>
						</div>
						<div>
							<p>Total Raise Goal</p>
							<span>₳ 8,000,000</span>
						</div>
						<div>
							<p>CERRA Price</p>
							<span>₳ 0,8</span>
						</div>
					</GradientBlock>
					<GradientBlock>
						<p>Referral System</p>
						<br />
						<span>
							Each community member can become a CERRA token sale
							referral. Once a wallet is connected, an automatic
							referral code is generated. After sharing the code
							with your friends, and they participate in the
							event, you receive a commission, equal to 10% of
							their contribution, paid in CERRA tokens.
						</span>
					</GradientBlock>
					<GradientBlock>
						<p>Token Distrbution</p>
						<br />
						<span>
							Once the Public sale even is completed, all
							purchased tokens, and if over-subscribed ADA are
							airdropped directly to your wallet. Also the
							Referral System rewards will be airdropped to your
							wallet.
							<br />
							<br />
							All automatic, no claiming is required.
						</span>
					</GradientBlock>
				</SummaryBlocks>
			</GradientBlock>
			<SummaryContent>
				<p className='title'>Cerra.io Progress so far</p>
				<span>
					Our project has been making significant strides lately, with
					the successful launch of our second product on the mainnet.
					Now, we offer both a <b>DEX</b> and <b>P2P-Lending</b>{' '}
					services on a single platform. The team has also introduced
					a <b>Profit-Sharing</b> module on the testnet, which will go
					live shortly after the public sale wraps up. Currently, it's
					undergoing an audit, and once finalized, the full potential
					of the <b>CERRA</b> token will be revealed. Token holders
					will then be entitled to 100% of the ADA profits generated
					by the platform.
					<br />
					<br />
					All of this has been achieved during the period of 8 months,
					where we mostly bootstrapped all development, marketing
					activities and audits. Showing the true dedication by the
					team to the Cardano Community.
					<br />
					<br />
					In addition, the team has announced that for the first 12
					months of the <b>Profit Sharing</b> module being live on the
					mainet, no team tokens will participate in the fee
					distribution, so all of that can be utilized by our
					community. And to top that off, the team will be funding the
					treasury themselves each epoch, to increase adoption and
					usability of the platform
				</span>
			</SummaryContent>
			<SummaryContent>
				<p className='title'>Cerra.io Future</p>
				<span>
					Each token sale bares the question, where will the funds be
					used? For us it is an easy answer, since we’ve been doing
					this for over half a year.
				</span>
			</SummaryContent>
			<SummaryContent>
				<GradientBlock>
					<p className='title'>KEY METRICS</p>
					<span>
						<span>DEX Liquidity</span> - We have announced that 3
						DEXes (Cerra / Sundae V3 / Minswap) will have our
						liquidity pools created to guarantee smooth initial
						trading volumes without any delayed orders.
					</span>
					<br />
					<br />
					<span>
						<span>Peer-to-Pool Lending</span> - this is going to be
						our 3rd product that we are already working on. Our goal
						is to create a seamless Pool Lending product that
						resembles features from projects on various other chains
						that everyone is familiar with. With a Cardano twist
						that can’t be achieved elsewhere!
					</span>
					<br />
					<br />
					<span>
						<span>Derivatives Trading</span> - Having a DEX and P2P
						Lending, we already have a great baseline to kick things
						off and introduce Cardano Community to Derivatives
						trading. A Product that hasn’t been around within the
						eco-system yet. Many traders are anticipating the
						release and our R&D is already in the works for it!
					</span>
					<br />
					<br />
					<span>
						<span>Team Expansion</span> - We need more hands,
						especially on the development side, with existing dev
						team switching full time on Cerra, and new members
						coming to help. Business Development and Marketing
						departments also require additional resources, that we
						couldn’t proceed with until now.
					</span>
					<br />
					<br />
					<span>
						<span>CEX Listings</span> - as we all know these are not
						cheap, but in order for Cardano eco-system to move
						forward, it is a mandatory thing, and we’ll make sure to
						get on as many exchanges as we can.
					</span>
					<br />
					<br />
					<span>
						We have delivered alot, but this is by far not all of
						it! Our roadmap is packed and we are excited to continue
						to bring innovation to the Cardano eco-system.
						Appreciate your time and trust in our team, let’s
						continue pushing!
					</span>
				</GradientBlock>
			</SummaryContent>
		</SummaryWrapper>
	);
};

export default Summary;
