import { Flex } from "../Common";
import styled from "styled-components";

export const MobileMenu = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  margin-top: 0;
  padding-top: 40px;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  a {
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    background: transparent;
    border: 2px solid var(--special);
    transition: all 0.3s ease;
    height: 45px;

    span {
      font-size: inherit;
    }
  }
  @supports (backdrop-filter: blur(40px)) {
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(40px);
  }
`;
export const Header = styled.header`
  padding: 20px 0px;
  max-width: 1220px;
  margin: 0 auto;
  ${Flex}:first-child {
    @media (min-width: 1024px) {
      & > div {
        height: 100%;
      }
    }
  }

  svg:nth-child(2) {
    margin-left: -48px;
  }

  /* svg:first-child {
    animation-delay: 1s;
    animation-name: logoIconRotate;
    -webkit-animation-name: logoIconRotate;
    animation-duration: 5s;
    -webkit-animation-duration: 5s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  } */

  @media (min-width: 1024px) {
    svg:nth-child(2) {
      margin-right: 80px;
    }
  }
  @media (max-width: 1024px) {
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    padding: 20px 20px;
    z-index: 99;
    background: var(--dbg);

    ${MobileMenu} {
      position: absolute;
      top: 70px;
      left: 0;
      transform: ${({ open }) =>
        open ? "translateX(0)" : "translateX(-100%)"};
      transition: transform 0.3s ease-in-out;
    }
  }

  @keyframes logoIconRotate {
    0%,
    40% {
      transform: rotate(0);
    }
    60% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

export const ChooseWalletWrap = styled.div`
  padding: 50px;
  h3 {
    margin-bottom: 20px;
  }
  @media (min-width: 767px) {
    min-width: 420px;
  }
  @media (max-width: 767px) {
    padding: 20px;
  }
`;

export const Glow = styled.div`
  a {
    color: black !important;
  }

  animation: glow-animation 1s infinite alternate;

  @keyframes glow-animation {
    0% {
      text-shadow: 0 0 1px #5fb646, 0 0 1px #5fb646, 0 0 3px #5fb646, 0 0 4px #5FB646;
    }

    50% {
      text-shadow: 0 0 5px #5fb646, 0 0 5px #5fb646, 0 0 15px #5fb646, 0 0 20px #5FB646;
    }

    100% {
      text-shadow: 0 0 5px #5fb646, 0 0 5px #5fb646, 0 0 15px #5fb646, 0 0 20px #5FB646;
    }
  }
`;

export const BoldMenu = styled.div`
  text-align: left;

  @keyframes slideDown {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes pulse {
    from {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1);
    }
    to {
      transform: scale(0.9);
    }
  }
  a {
    font-weight: bold;
    animation: pulse 3s infinite ease;
    display: inline-block;
    color: var(--text);

    span {
      color: var(--special);
      animation: slideDown 2s ease;
      display: inline-block;
      font-size: inherit;
    }
  }
`;
