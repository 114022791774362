export const AdaImage = () => (
  <img
    alt="ADA"
    height="20px"
    src={`data:image/png;base64,${window.ADA.metadata.metadata_logo}`}
  />
);

export const CerraImage = () => (
  <img
    alt="CERRA"
    height="20px"
    src={`data:image/png;base64,${window.SOLD_TOKEN.metadata.metadata_logo}`}
  />
);
