import './ConnectOrChangeWallet.scss'
import React, { useState } from 'react'
import { useConnectWallet, WalletModal } from '@newm.io/cardano-dapp-wallet-connector'
import Button from './Button'

const ConnectOrChangeWallet = () => {
    const { isConnected, wallet, disconnect } = useConnectWallet()
    const [walletModalOpen, setWalletModalOpen] = useState(false)

    return (
        <>
            {/*https://github.com/projectNEWM/cardano-dapp-wallet-connector?tab=readme-ov-file#connectwallet - for styling modal and button*/}
            <div className={`wallet-button${isConnected ? ' wallet-button--connected' : ''}`}>
                <Button onClick={() => (isConnected ? disconnect() : setWalletModalOpen(!walletModalOpen))}>
                    <div className="wallet-button__content">
                        {isConnected ? (
                            <>
                                <img src={wallet?.icon} alt={wallet?.name} /> <span>Connected</span>
                            </>
                        ) : (
                            'Connect Wallet'
                        )}
                    </div>
                </Button>
            </div>
            <div className="wallet-modal">
                <WalletModal isOpen={walletModalOpen} onClose={() => setWalletModalOpen(false)} isInverted={true} />
            </div>
        </>
    )
}

export default ConnectOrChangeWallet
