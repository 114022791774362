import {
	PolarAngleAxis,
	RadialBar,
	RadialBarChart,
	ResponsiveContainer,
} from 'recharts';

const SaleChart = ({ percent = 0 }) => {
	const data = [
		{
			name: 'X4',
			percent:
				percent - 300 > 100
					? 100
					: percent - 300 < 0
					? 0
					: percent - 300,
			fill: '#295e1a',
		},
		{
			name: 'X3',
			percent:
				percent - 200 > 100
					? 100
					: percent - 200 < 0
					? 0
					: percent - 200,
			fill: '#397c27',
		},
		{
			name: 'X2',
			percent:
				percent - 100 > 100
					? 100
					: percent - 100 < 0
					? 0
					: percent - 100,
			fill: '#74d359',
		},
		{
			name: 'Goal',
			percent: percent > 100 ? 100 : percent,
			fill: '#b2fd9d',
		},
	];
	return (
		<div style={{ width: 300, height: 300 }}>
			<ResponsiveContainer width='100%' height='100%'>
				<RadialBarChart
					data={data}
					cx='50%'
					cy='50%'
					innerRadius='30%'
					outerRadius='110%'
					barSize={20}
					startAngle={90}
					endAngle={470}
				>
					<PolarAngleAxis
						type='number'
						domain={[0, 107]}
						angleAxisId={0}
						tick={false}
					/>
					<RadialBar
						minAngle={15}
						background={{ fill: '#151515' }}
						clockWise={true}
						dataKey='percent'
						cornerRadius={100}
						paddingAngle={5}
						denominator='pv'
						angleAxisId={0}
					/>
				</RadialBarChart>
			</ResponsiveContainer>
		</div>
	);
};

export default SaleChart;
